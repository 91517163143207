import _ from 'lodash'
import VueDropzone from 'vue2-dropzone'
import ResponseModal from '../ResponseModal'
import ViewModal from '../../../../components/part/shared/PreviewViewModal'
import erResponseService from '../../../../services/provider/erresponse'
import moment from 'moment-timezone'
import VueTagsInput from '@johmun/vue-tags-input'
import userServices from '../../../../services/user'
import siteService from '../../../../services/provider/sites'
import store from '../../../../store'
import { getParentOrgType } from '../../../../misc/auth'
import recallServicesProv from '../../../../services/provider/recall'
import { EMAIL_STATUS, ACTION_TYPE } from '../../../../misc/commons'
export default {
    components: {
        VueDropzone,
        ResponseModal,
        VueTagsInput,
        ViewModal
    },
    props: ['infoData', 'recallMetaData', 'recallDisabled'],
    data() {
        return {
            nullTextCheck: '',
            defaultEmailList: [],
            combineEmailList: [],
            createSupplierEmail: [],
            createAdditionalEmail: [],
            item: {
                suppliername: null,
                name: null,
                recallid: null,
                id: null,
                response_email: null,
                documents: null,
                createdBy: null,
                notificationDate: null,
                supplierEmail: null,
                affectedProduct: false,
                IsFirstNotice: false,
                disposalMethod: '',
                disposalMethodValue: '',
                disposalMethodView: '',
                response_date: '',
                FileLen: 0,
                responseCcEmails: null,
                additionalEmailsList: null,
                siteId: null
            },
            erStatus: EMAIL_STATUS,
            actionType: ACTION_TYPE,
            sites: [],
            notificationValue: 0,
            notone: 1,
            nottwo: 2,
            notthree: 3,
            notfour: 4,
            class1: '',
            class2: '',
            class3: '',
            class4: '',
            css1: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css2: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css3: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            css4: 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;',
            formType: 'INSERT',
            recallId: null,
            errorList: [],
            RecallsList: [],
            sup: 'sup',
            sup1: 'sup1',
            filesUpload: [],
            NotificationfilesUpload: [],
            filespaths: [],
            s3upload: [],
            IsUpdated: false,
            filesUploadUpdate: [],
            filesUploadOriginal: [],
            NotificationfilesUploadOriginal: [],
            respModalKey: 0,
            viewModalKey: 0,
            cnt: 0,
            uploadcnt: 0,
            thumbnailIndex: -1,
            updateRecallId: '',
            IsVal: false,
            disabledDates: {
                from: new Date()
            },
            disableEmailTagList: false,
            autocompleteItems: [],
            valueTag: [],
            originalValueTag: [],
            disableValueTag: [],
            enableValueTag: [],
            tags: [],
            tag: '',
            emailError: '',
            value: [],
            autocompleteItemsR: [],
            valueTagR: [],
            tagR: '',
            emailErrorR: '',
            valueR: [],
            valueRO: '',
            options: [],
            recallsite: [],
            autocompleteItemslst: [],
            SupplierContactlst: [],
            autonotifychecked: false
        }
    },
    mounted() {
        this.info = _.cloneDeep(this.infoData)
        this.fetchSites()
        this.fetchsuppliernameList()

    },
    created() {
        this.getEmailList()
    },
    watch: {
        'item.response_email'(val) {
            this.item.response_email = val.trim()
        },
    },
    computed: {
        form() {
            if (this.formType == this.actionType.INSERT) {
                return {
                    title: 'Add Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class: 'card-title'
                }
            }
            if (this.formType == this.actionType.UPDATE) {
                return {
                    title: 'Update Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
            if (this.formType == 'RES_UPDATE') {
                return {
                    title: 'Recall Response',
                    showAdminCreation: false,
                    showStatusSwitcher: true
                }
            }
            if (this.formType == this.actionType.VIEW) {
                return {
                    title: 'Recall Response',
                    showAdminCreation: true,
                    showStatusSwitcher: false,
                    class: 'card-title'
                }
            }
        },
        filteredItems() {
            return this.autocompleteItemsR.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
            })
        },
        filteredEmailItems() {
            return this.autocompleteItemsR.filter((i) => {
                return i.text.toLowerCase().indexOf(this.tagR.toLowerCase()) !== -1
            })
        },
    },
    methods: {
        getEmailList() {
            recallServicesProv
                .GetEmailList(this.$store.state.orgId)
                .then((resp) => {
                    if (!resp.error) {
                        resp.data.d.forEach((val) => {
                            this.autocompleteItemsR.push({
                                text: val.email
                            })
                        })
                    }
                })
        },
        handleChangeSupplierName(suppliername) {
            var supplierlst = this.SupplierContactlst.filter(s => s.supplierName == suppliername)
            this.defaultEmailList = supplierlst
            if (this.autonotifychecked) {
                this.valueTag = []
                this.originalValueTag = []
                this.enableValueTag = []
                this.options = []
                this.value = []
                supplierlst.forEach(x => {
                    if (!this.options.includes(x.email)) {
                        this.options.push(x.email)
                    }
                    if (!this.value.includes(x.email)) {
                        this.value.push(x.email)
                    }
                    if (this.valueTag.filter(s => s.text == x.email).length <= 0) {
                        this.originalValueTag.push({ 'text': x.email, 'classes': ['ti-tag-disable-close'] })
                        this.valueTag.push({ 'text': x.email, 'classes': ['ti-tag-disable-close'] })
                        this.disableEmailTagList = true
                    }
                })
            }
            if (supplierlst.length < 1 && this.formType == 'UPDATE') {
                this.disableEmailTagList = false
            }
            if (supplierlst.length < 1 && this.formType != 'UPDATE') {
                this.disableEmailTagList = false
            }
        },
        fetchSupplierContactList() {
            erResponseService.findSupplierContacts(this.$store.state.orgId).then(resp => {
                if (!resp.error) {
                    this.SupplierContactlst = resp.data.d
                }
            })
        },
        getAutoNotifySetting() {
            erResponseService.getAutoNotifySetting().then(resp => {
                if (!resp.error) {
                    this.autonotifychecked = resp.data.d.isautonotify
                    this.fetchSupplierContactList()
                }
            })
        },
        fetchsuppliernameList() {
            erResponseService.GetSupplierNameList().then((res) => {
                for (let i = 0; i < res.data.d.length; i++) {
                    this.autocompleteItemslst.push(res.data.d[i].text)
                }
            })
        },
        fetchSites() {
            var ParentOrgType = getParentOrgType()
            var role = store.state.roleName
            if (ParentOrgType == 'PROVIDER' && (store.state.roleName === 'Recall Coordinator')) {
                erResponseService.getSites().then(res => {
                    if (!res.error) {
                        this.sites = res.data.d
                        if (this.formType == 'INSERT') {
                            this.getUserSitesById()
                        }
                    }
                })
            }
            if (ParentOrgType == 'PROVIDER' && (store.state.roleName == 'Responder')) {
                erResponseService.getSites().then(res => {
                    if (!res.error) {
                        this.sites = res.data.d
                        if (this.formType == this.actionType.INSERT) {
                            this.getUserSitesById()
                        }
                    }
                })
            }
            if ((ParentOrgType === 'NOTISPHERE' || ParentOrgType === 'PROVIDER') && (store.state.roleName != 'Recall Coordinator' && store.state.roleName != 'Responder')) {
                userServices.getSitesMeta(this.$store.state.orgId).then(resp => {
                    if (!resp.error) {
                        this.sites = resp.data.d
                        if (this.formType == this.actionType.INSERT) {
                            this.getUserSitesById()
                        }
                    }
                })
            }
        },
        async removeDocument(index) {
            this.filesUpload.splice(index, 1)
            this.thumbnailIndex = this.filesUpload.findIndex(x => x.is_thumbnail === true)
            if (this.formType == 'RES_UPDATE') {
                const isValid = await this.$refs['observer'].validate()
                if (this.item.Files.length > 0 || this.filesUpload.length > 0 && this.valueR.length > 0)
                    this.IsVal = true
                else
                    this.IsVal = false
            }
        },
        async removeNotificationDocument(index) {
            this.NotificationfilesUpload.splice(index, 1)
            this.thumbnailIndex = this.NotificationfilesUpload.findIndex(x => x.is_thumbnail === true)
            if (this.formType == 'RES_UPDATE') {
                const isValid = await this.$refs['observer'].validate()
                if (this.item.Files.length > 0 || this.NotificationfilesUpload.length > 0 && this.valueR.length > 0)
                    this.IsVal = true
                else
                    this.IsVal = false
            }
        },
        async handleFilesUpload() {
            let uploadedFiles = this.$refs.files.files
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].size == 0) {
                    this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                }
                else {
                    if (this.formType == this.actionType.UPDATE || this.formType == 'RES_UPDATE') {
                        this.filesUpload.push({
                            name: uploadedFiles[i].name,
                            type: uploadedFiles[i].type,
                            size: uploadedFiles[i].size,
                            file: uploadedFiles[i],
                        })
                    }
                    else {
                        this.filesUpload.push({
                            file: uploadedFiles[i],
                            note: '',
                            is_thumbnail: false
                        })
                    }
                }
            }
            if (this.formType == 'RES_UPDATE') {
                const isValid = await this.$refs['observer'].validate()
                if (this.item.Files.length > 0 || this.filesUpload.length > 0 && this.valueR.length > 0)
                    this.IsVal = true
                else
                    this.IsVal = false
            }
            this.$refs.files.value = ''
        },
        async handleNotificationFilesUpload() {
            let uploadedFiles = this.$refs.files1.files
            let isError = false
            if (uploadedFiles[0].name && _.isArray(uploadedFiles[0].name.split('.'))) {
                for (let i = 0; i < uploadedFiles.length; i++) {
                    if (uploadedFiles[i].size == 0) {
                        this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
                        isError = true
                    }
                    else {
                        if (this.formType == 'UPDATE' || this.formType == 'RES_UPDATE') {
                            this.NotificationfilesUpload.push({
                                name: uploadedFiles[i].name,
                                type: uploadedFiles[i].type,
                                size: uploadedFiles[i].size,
                                file: uploadedFiles[i],
                            })
                        }
                        else {
                            this.NotificationfilesUpload.push({
                                file: uploadedFiles[i],
                                note: '',
                                is_thumbnail: false,
                            })
                        }
                    }
                }
                if (this.formType == 'RES_UPDATE') {
                    const isValid = await this.$refs['observer'].validate()
                    if (this.item.Files.length > 0 || this.NotificationfilesUpload.length > 0 && this.valueR.length > 0)
                        this.IsVal = true
                    else
                        this.IsVal = false
                }
                this.$refs.files1.value = ''
                if (!isError) {
                    this._showToast('File uploaded successfully.', { variant: 'success' })
                }
            }
        },
        forceRerenderInsert(list) {
            this.getAutoNotifySetting()
            this.formType = this.actionType.INSERT
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.$refs.modal.show()
        },
        ViewClick(val) {
            let itemVal = {
                extension: val.extension,
                filename: val.name.replace(val.extension, ''),
                signed_url: val.path,
                signed_url_review: val.signed_url_review,
                responder_document: val.responder_document,
                responder_recallid: val.responder_recallid,
                fileSize: val.size,
                mimetype: val.type
            }
            this.viewModalKey += 1
            this.$nextTick(() => {
                this.$refs.viewModal.forceRerenderInsert(itemVal)
            })
        },
        getUserSitesById() {
            siteService.getUserSitesById().then(resp => {
                if (!resp.error) {
                    var selectedorg = []
                    if (resp.data.d != null && resp.data.d.length > 0) {
                        for (let i = 0; i < resp.data.d.length; i++) {
                            selectedorg.push(
                                resp.data.d[i].value
                            )
                        }
                    }
                    else {
                        var Corporatesitemeta = this.sites.filter(s => s.text == 'Corporate')
                        for (let i = 0; i < Corporatesitemeta.length; i++) {
                            selectedorg.push(
                                Corporatesitemeta[i].value
                            )
                        }
                    }
                    this.recallsite = selectedorg
                }
            })
        },
        async onChangeEvent(eve) {
            const isValid = await this.$refs['observer'].validate()
            if (eve.Files.length > 0 || this.filesUpload.length > 0 && this.valueR.length > 0)
                this.IsVal = true
            else
                this.IsVal = false
        },
        forceRerenderView(list) {
            this.formType = 'VIEW'
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.$refs.modal.show()
        },
        forceRerenderUpdate(val, list, Mode) {
            this.getAutoNotifySetting()
            var supplierCCEmailList = val.responseCcEmails
            var additionalCcEmailsList = val.additionalCcEmails
            this.combineEmailList = supplierCCEmailList?.concat(additionalCcEmailsList)
            this.formType = Mode
            list.forEach((x, i) => {
                this.RecallsList.push(x.recallid.trim())
            })
            this.updateRecallId = val.recallid.trim()
            this.item = {
                suppliername: val.supplier_name,
                distributorName: val.distributorName,
                name: val.recallname,
                recallid: val.recallid,
                id: val.responder_recallid,
                status: val.response_status == this.erStatus.ERROR ? this.erStatus.BOUNCE : val.response_status,
                response_email: val.supplier_email,
                notificationDate: val.notification_date,
                notificationValue: val.notificationValue,
                IsFirstNotice: val.notificationValue == 1 ? true : val.isFirstNotice == null ? false : val.isFirstNotice,
                response_date: val.response_date,
                disposalMethodView: val.disposal_method == undefined || val.disposal_method == null ? '' : val.disposal_method,
                disposalMethod: val.disposal_method == 'Destroyed' ? true : (val.disposal_method == 'Returned' ? false : ''),
                affectedProduct: val.disposal_method == '' ? null : val.affected_product,
                disposalMethodValue: val.disposal_method,
                documents: null,
                createdBy: null,
                supplierEmail: val.supplier_email,
                Files: [],
                responseCcEmails: supplierCCEmailList,
                additionalEmailsList: additionalCcEmailsList,
                siteId: val.siteId,
                siteName: val.site,
                recallSites: val.recall_sites
            }
            this.filesUploadOriginal = []
            this.filesUploadOriginal = val.files
            val.files.forEach((x, i) => {
                if (x.extension != null) {
                    this.filesUpload.push({
                        name: x.filename + x.extension,
                        extension: x.extension,
                        type: x.mimetype,
                        size: x.fileSize,
                        path: x.signed_url,
                        signed_url_review: x.signed_url_review,
                        pathS: x.path,
                        responder_document: x.responder_document,
                        responder_recallid: x.responder_recallid,
                    })
                }

            })
            //for notification documents
            this.NotificationfilesUploadOriginal = []
            this.NotificationfilesUploadOriginal = val.notificationfiles
            val.notificationfiles.forEach((x, i) => {
                if (x.extension != null) {
                    this.NotificationfilesUpload.push({
                        name: x.filename + x.extension,
                        extension: x.extension,
                        type: x.mimetype,
                        size: x.fileSize,
                        path: x.signed_url,
                        signed_url_review: x.signed_url_review,
                        pathS: x.path,
                        responder_document: x.respondernotificationdocId,
                        responder_recallid: x.responder_recallid,
                    })
                }

            })
            this.valueRO = val.supplier_email
            if (val.supplier_email != '' & val.supplier_email != null) {
                this.valueR.push(val.supplier_email)
                this.valueTagR.push(val.supplier_email)
            }
            this.combineEmailList.forEach(x => {
                this.options.push(x)
                this.value.push(x)
            })
            supplierCCEmailList.forEach(x => {
                if (this.valueTag.filter(s => s.text == x).length <= 0) {
                    this.originalValueTag.push({ 'text': x, 'classes': ['ti-tag-disable-close'] })
                    this.valueTag.push({ 'text': x, 'classes': ['ti-tag-disable-close'] })
                    this.disableValueTag.push({ 'text': x, 'classes': ['ti-tag-disable-close'] })
                    this.disableEmailTagList = true
                }
            })
            additionalCcEmailsList?.forEach(x => {
                if (this.valueTag.filter(s => s.text == x).length <= 0) {
                    this.disableValueTag.push({ 'text': x })
                    this.enableValueTag.push({ 'text': x })
                    this.valueTag.push({ 'text': x })
                }
            })
            supplierCCEmailList?.forEach((val) => {
                this.defaultEmailList.push({ email: val })
            })
            var selectedorg = []
            if (val.errecallsite != null) {
                for (let i = 0; i < val.errecallsite.length; i++) {
                    selectedorg.push(
                        val.errecallsite[i].value
                    )
                }
            }
            this.recallsite = selectedorg
            this.$refs.modal.show()
            this.class1 = ''
            this.class2 = ''
            this.class3 = ''
            this.class4 = ''
            this.css1 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css2 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css3 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.css4 = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.notificationValue = this.item.notificationValue
            if (this.item.notificationValue == 1) {
                this.class1 = 'primary'
                this.css1 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = true
            }
            else if (this.item.notificationValue == 2) {
                this.class2 = 'primary'
                this.css2 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
            }
            else if (this.item.notificationValue == 3) {
                this.class3 = 'primary'
                this.css3 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
            }
            else if (this.item.notificationValue == 4) {
                this.class4 = 'primary'
                this.css4 = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
            }
            if (this.item.notificationDate != null) {
                this.nullTextCheck = moment(this.item.notificationDate).format('MM/DD/YYYY')
            }
            if (val.status == 'ERROR' && Mode == 'RES_UPDATE' && val.files.length > 0 && val.supplier_email) {
                this.IsVal = true
            }
        },
        async createRecall(e) {
            const isValid = await this.$refs['observer'].validate()
            if (isValid) {
                var cnt = 0
                for (let k = 0; k < this.value.length; k++) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    var b = re.test(String(this.value[k].trim()).toLowerCase())
                    this.removevalidation()
                    if (!b) {
                        this.setvalidation()
                        cnt = cnt + 1
                    }
                }
                //Add duplicate email validation
                if (this.value.length > 1) {
                    for (let i = 0; i < this.value.length; i++) {
                        for (var k = i + 1; k < this.value.length; k++) {
                            if (this.value[i].trim() === this.value[k].trim()) {
                                this.setvalidation()
                                return this.emailError = 'The email has been already used.'
                            }

                        }

                    }

                }
                if (this.value.length > 0) {
                    for (let k = 0; k < this.valueTag.length; k++) {
                        if (this.value.find(x => x == this.valueTag[k].text)) {
                        }
                        else {
                            this.setvalidation()
                            return this.emailError = 'The email has been already used.'
                        }
                    }
                }
                if (cnt == 0) {
                    let formData = new FormData()
                    formData.append('supplierName', this.item.suppliername)
                    formData.append('distributorName', this.item.distributorName)
                    formData.append('communicationId', this.item.recallid)
                    formData.append('communicationName', this.item.name)
                    formData.append('notificationDate', this.item.notificationDate ? moment(this.item.notificationDate).format('YYYY/MM/DD') : '')
                    formData.append('responseStatus', this.erStatus.DRAFT)
                    formData.append('disposalMethod', this.item.disposalMethodValue ?? '')
                    formData.append('affectedProduct', this.item.affectedProduct)
                    formData.append('notificationValue', this.notificationValue)
                    if (this.recallsite) {
                        this.recallsite.forEach(siteId => {
                            formData.append('siteId[]', siteId)
                        })
                    }
                    formData.append('isFirstNotice', this.notificationValue == 1 ? true : this.item.IsFirstNotice)
                    this.filesUpload.forEach((x, i) => {
                        if (!x.id) {
                            formData.append('document', x.file)
                        }
                    })
                    this.NotificationfilesUpload.forEach((x, i) => {
                        if (!x.id) {
                            formData.append('notificationdocument', x.file)
                        }
                    })
                    if (this.valueR.length > 0) {
                        formData.append('supplierEmail', this.valueR[0])
                    }
                    if (this.value.length > 0) {
                        for (let k = 0; k < this.value.length; k++) {
                            if (!this.defaultEmailList.find(x => x.email == this.value[k])) {
                                formData.append('additionalCcEmails', this.value[k])
                                this.createAdditionalEmail.push(this.value[k])
                            }
                        }
                    }
                    for (let i = 0; i < this.defaultEmailList.length; i++) {
                        formData.append('supplierRepCCEmails', this.defaultEmailList[i].email)
                        this.createSupplierEmail.push(this.defaultEmailList[i].email)
                    }
                    this.item.responseCcEmails = this.createSupplierEmail
                    this.item.additionalEmailsList = this.createAdditionalEmail
                    erResponseService.saveResponderRecall(formData).then(resp => {
                        if (!resp.error) {
                            this.$refs.modal.hide()
                            this.item.FileLen = this.filesUpload.length
                            this.item.status = this.erStatus.DRAFT
                            this.item.responder_recallid = resp.data.d
                            if (this.valueR.length > 0) {
                                this.item.response_email = this.valueR[0]
                            }
                            this._showToast('Added successfully!')
                            this.item.mode = 'INSERT'
                            this.$emit('onCompleted', this.item)
                        } else {
                            if (resp.data.c == 'err_recallId_exist') {
                                this.$refs.observer.setErrors({ 'suppliername': ['Recall Id - Supplier Name combination already exists.'] })
                                this.$refs.observer.setErrors({ 'recallid': ['Recall Id - Supplier Name combination already exists.'] })
                            }
                        }
                    })
                }
                else {
                    this._showToast('Invalid email address.', { variant: 'danger' })
                }
            }
        },
        async updateRecall(e, Mode) {
            this.createSupplierEmail = []
            this.createAdditionalEmail = []
            var isValid = await this.$refs['observer'].validate()
            if (isValid) {
                var cnt = 0
                for (let k = 0; k < this.value.length; k++) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    var b = re.test(String(this.value[k]))
                    if (!b) {
                        this.removevalidation()
                        this.setvalidation()
                        cnt = cnt + 1
                    }
                }
                //Add duplicate email validation
                if (this.value.length > 1 || this.value.length > 0) {
                    for (let i = 0; i < this.value.length; i++) {
                        for (var k = i + 1; k < this.value.length; k++) {
                            if (this.value[i].trim() === this.value[k].trim()) {
                                this.setvalidation()
                                return this.emailError = 'The email has been already used.'
                            }

                        }
                    }
                }
                if (this.value.length > 0) {
                    for (let k = 0; k < this.valueTag.length; k++) {
                        if (this.value.find(x => x == this.valueTag[k].text)) {
                        }
                        else {
                            this.setvalidation()
                            return this.emailError = 'The email has been already used.'
                        }
                    }
                }
                for (let k = 0; k < this.valueR.length; k++) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    let b = re.test(String(this.valueR[k].trim()).toLowerCase())
                    this.removeResponsevalidation()
                    if (!b) {
                        //removeResponsevalidation
                        this.setResponsevalidation()
                        cnt = cnt + 1
                    }
                }

                //Add Single email validation
                if (this.valueR.length > 1) {
                    this.setResponsevalidation()
                    return this.emailErrorR = 'The email has been already used.'
                }
                if (cnt == 0) {
                    let formData = new FormData()
                    formData.append('supplierName', this.item.suppliername)
                    formData.append('distributorName', this.item.distributorName)
                    formData.append('communicationId', this.item.recallid)
                    formData.append('communicationName', this.item.name)
                    formData.append('notificationDate', this.item.notificationDate ? moment(this.item.notificationDate).format('YYYY/MM/DD') : '')
                    formData.append('responseStatus', this.erStatus.DRAFT)
                    formData.append('disposalMethod', (this.item.disposalMethodValue == null || this.item.disposalMethodValue == undefined) ? '' : this.item.disposalMethodValue)
                    formData.append('affectedProduct', this.item.affectedProduct == true ? true : false)
                    formData.append('notificationValue', this.notificationValue)
                    formData.append('isFirstNotice', this.notificationValue == 1 ? true : this.item.IsFirstNotice)
                    if (this.recallsite) {
                        this.recallsite.forEach(siteId => {
                            formData.append('siteId[]', siteId)
                        })
                    }
                    this.filesUpload.forEach((x, i) => {
                        if (x.file != undefined) {
                            formData.append('document', x.file)
                        }
                        else {
                            formData.append('actualfiles[' + i + '].responderrecallId', x.responder_recallid)
                            formData.append('actualfiles[' + i + '].filename', x.name)
                            formData.append('actualfiles[' + i + '].responderdocumentId', x.responder_document)
                            formData.append('actualfiles[' + i + '].path', x.pathS)
                            formData.append('actualfiles[' + i + '].extension', x.extension)
                            formData.append('actualfiles[' + i + '].mimetype', x.type)
                            formData.append('actualfiles[' + i + '].signedurl', x.signed_url)
                        }
                    })
                    this.filesUploadOriginal.forEach((x, i) => {
                        formData.append('files[' + i + '].responderrecallId', x.responder_recallid)
                        formData.append('files[' + i + '].filename', x.filename)
                        formData.append('files[' + i + '].responderdocumentId', x.responder_document)
                        formData.append('files[' + i + '].path', x.path)
                        formData.append('files[' + i + '].extension', x.extension)
                        formData.append('files[' + i + '].mimetype', x.mimetype)
                        formData.append('files[' + i + '].signedurl', x.signed_url)
                    })
                    //for Notification documents
                    this.NotificationfilesUpload.forEach((x, i) => {
                        if (x.file != undefined) {
                            formData.append('notificationdocument', x.file)
                        }
                        else {
                            formData.append('notificationactualfiles[' + i + '].responderrecallId', x.responder_recallid)
                            formData.append('notificationactualfiles[' + i + '].filename', x.name)
                            formData.append('notificationactualfiles[' + i + '].respondernotificationdocId', x.responder_document)
                            formData.append('notificationactualfiles[' + i + '].path', x.pathS)
                            formData.append('notificationactualfiles[' + i + '].extension', x.extension)
                            formData.append('notificationactualfiles[' + i + '].mimetype', x.type)
                            formData.append('notificationactualfiles[' + i + '].signedurl', x.signed_url)
                        }
                    })
                    this.NotificationfilesUploadOriginal.forEach((x, i) => {
                        formData.append('notificationfiles[' + i + '].responderrecallId', x.responder_recallid)
                        formData.append('notificationfiles[' + i + '].filename', x.filename)
                        formData.append('notificationfiles[' + i + '].respondernotificationdocId', x.responder_document)
                        formData.append('notificationfiles[' + i + '].path', x.path)
                        formData.append('notificationfiles[' + i + '].extension', x.extension)
                        formData.append('notificationfiles[' + i + '].mimetype', x.mimetype)
                        formData.append('notificationfiles[' + i + '].signedurl', x.signed_url)
                    })
                    if (this.valueR.length > 0) {
                        formData.append('supplierEmail', this.valueR[0])
                    }
                    if (this.value.length > 0) {
                        for (let k = 0; k < this.value.length; k++) {
                            if (!this.defaultEmailList.find(x => x.email == this.value[k])) {
                                formData.append('additionalCcEmails', this.value[k])
                                this.createAdditionalEmail.push(this.value[k])
                            }
                        }
                    }
                    for (let i = 0; i < this.defaultEmailList.length; i++) {
                        formData.append('supplierRepCCEmails', this.defaultEmailList[i].email)
                        this.createSupplierEmail.push(this.defaultEmailList[i].email)
                    }
                    this.item.responseCcEmails = this.createSupplierEmail
                    this.item.additionalEmailsList = this.createAdditionalEmail
                    erResponseService.updateResponderRecall(formData, this.item.id, 'UPDATE').then(resp => {
                        if (!resp.error) {
                            this.$refs.modal.hide()
                            this.item.FileLen = this.filesUpload.length
                            this.item.files = this.filesUpload
                            if (this.valueR.length > 0) {
                                this.item.response_email = this.valueR[0]
                            }
                            else {
                                this.item.response_email = null
                            }
                            this.item.mode = this.actionType.UPDATE
                            this.$emit('onCompleted', this.item)
                        }
                        else {
                            if (resp.data.c == 'err_recallId_exist') {
                                this.$refs.observer.setErrors({ 'suppliername': ['Recall Id - Supplier Name combination already exists.'] })
                                this.$refs.observer.setErrors({ 'recallid': ['Recall Id - Supplier Name combination already exists.'] })
                            }

                        }
                    })
                }
                else {
                    this._showToast('Invalid email address.', { variant: 'danger' })
                }
            }
        },
        isFirstNoticeClicked(eve) {
            if (eve.target.checked) {
                this.item.isFirstNotice = true
            }
            else {
                this.item.IsFirstNotice = false
            }
        },
        isChange(eve) {
            this.item.disposalMethod = ''
            this.item.disposalMethodValue = ''
            if (eve.target.checked) {
                this.item.affectedProduct = true
            }
            else {
                this.item.affectedProduct = false
            }
        },
        notificationclick(eve) {
            this.notificationValue = eve
            this.class1 = ''
            this.class2 = ''
            this.class3 = ''
            this.class4 = ''
            this.$refs.bnt1.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt2.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt3.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            this.$refs.bnt4.style = 'background-color: #fff;border: 2px solid #ccc;color: #333;width: 2.7em;margin-right: 5px;padding-left: 10px;'
            if (eve == 1) {
                this.class1 = 'primary'
                this.$refs.bnt1.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = true
            }
            else if (eve == 2) {
                this.class2 = 'primary'
                this.$refs.bnt2.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
            else if (eve == 3) {
                this.class3 = 'primary'
                this.$refs.bnt3.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
            else if (eve == 4) {
                this.class4 = 'primary'
                this.$refs.bnt4.style = 'width: 2.7em;margin-right: 5px;padding-left: 10px;'
                this.item.IsFirstNotice = false
            }
        },
        isDisposal(eve) {
            if (eve) {
                this.item.disposalMethodValue = 'Destroyed'
            }
            else {
                this.item.disposalMethodValue = 'Returned'
            }
        },
        async download(dataurl) {
            this.ViewClick(dataurl)
        },
        addCheck(val) {
            let isDuplicateEmail = false
            let tagArray = []
            let emailArray = []
            this.valueTag = []
            let duplicateEmail = []
            this.originalValueTag.forEach(x => {
                if (emailArray.includes(x.text)) {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                emailArray.push(x.text)
                tagArray.push(x)
            })
            val.forEach(x => {
                if (x.tiClasses.includes('ti-duplicate')) {
                    duplicateEmail.push(x.text)
                    isDuplicateEmail = true
                }
                if (this.originalValueTag.find(y => y.text == x.text) == null && !x.tiClasses.includes('ti-duplicate')) {
                    tagArray.push(x)
                }
            })
            val = []
            this.valueTag = tagArray
            val = tagArray
            if (isDuplicateEmail) {
                duplicateEmail.forEach(x => {
                    val.push(x)
                })
                this.setvalidation()
                this.emailError = 'The email has been already used.'
            }
            else {
                if (val.length > 0) {
                    this.value = []
                    val.forEach(x => {
                        this.checkEmailValidation(x.text)
                        if (!this.emailError) {
                            this.value.push(x.text)
                        }
                    })
                    if (this.value.length > 0) {
                        var valueOriginal = this.value.length
                        var valcurrent = val.length - 1
                        const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
                        var b = re.test(String(val[valcurrent].text.trim()).toLowerCase())
                        if (b) {
                            var i = 0
                            for (i = 0; i < valueOriginal - 1; i++) {
                                if (this.value[i].trim() === val[valcurrent].text.trim()) {
                                    this.setvalidation()
                                    this.emailError = 'The email has been already used.'
                                }
                            }
                        }
                        if (b) {
                            for (let i = 0; i < this.value.length; i++) {
                                if (!(this.valueTag.find(x => x.text == val[valcurrent].text) != null)) {
                                    this.setvalidation()
                                    this.emailError = 'The email has been already used.'
                                }
                            }
                        }
                    }
                }
                else {
                    this.removevalidation()
                    this.value = []
                    this.domainError = ''
                }
            }
        },
        async addRespCheck(val) {
            if (val.length > 1) {
                this._showToast('Only one response email allowed', { variant: 'warning' })
                val.pop()
            }
            else {
                if (val.length > 0) {
                    this.valueR = []
                    val.forEach(x => {
                        let IsVal1 = this.checkResponseEmailValidation(x.text)
                        if (!this.emailErrorR && IsVal1) {
                            this.valueR.push(x.text)
                        }
                        else {
                            val.pop()
                        }
                    })
                }
                else {
                    this.removeResponsevalidation()
                    this.valueR = []
                    this.domainError = ''
                }
            }
            if (this.item.Files.length > 0 || this.filesUpload.length > 0 && this.valueR.length > 0)
                this.IsVal = true
            else
                this.IsVal = false
        },
        checkResponseEmailValidation(email) {
            let Isval1 = true
            if (!email) {
                this.setResponsevalidation()
                this.emailErrorE = 'Email is required'
                Isval1 = false
            } else { this.emailErrorE = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removeResponsevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'warning' })
                    this.setResponsevalidation()
                    Isval1 = false
                }
            }
            return Isval1
        },
        checkEmailValidation(email) {
            if (!email) {
                this.setvalidation()
                this.emailError = 'Email is required'
            } else { this.emailError = '' }
            if (email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                var b = re.test(String(email.trim()).toLowerCase())
                this.removevalidation()
                if (!b) {
                    this._showToast('Please enter valid email address', { variant: 'danger' })
                    this.setvalidation()
                }
            }
        },
        setResponsevalidation() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:red !important'
        },
        removeResponsevalidation() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = ' '
        },
        addResponseemailcolor() {
            var ele = this.$refs.recipientsRespEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:#5ec3ff !important'
        },

        setvalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:red !important'
        },
        removevalidation() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = ' '
        },
        addemailcolor() {
            var ele = this.$refs.recipientsEmail.$el.getElementsByClassName('ti-input')
            ele[0].style = 'border-color:#5ec3ff !important'
        },
    }
}